import React, { useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { DRAWER_WIDTH } from "../../constants";
import { scrollToCategory } from "../../utils";
import { ICategoriesKeysProps } from "../../types";

interface ILeftDrawer extends ICategoriesKeysProps {
  open: boolean;
  setOpen: (param: boolean) => void;
}

const useStyles = makeStyles()((theme, params, classes) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export default function LeftDrawer(props: ILeftDrawer) {
  const { categories } = props;
  const { classes } = useStyles();

  const { open, setOpen } = props;

  const [items] = useState(categories);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <SwipeableDrawer
      className={classes.drawer}
      variant="temporary"
      anchor="left"
      open={open}
      onBackdropClick={handleDrawerClose}
      onClose={handleDrawerClose}
      onOpen={() => setOpen(true)}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {items.map((buttonText, index) => (
          <ListItem button color="secondary" key={index}>
            <ListItemText
              key={index}
              primary={buttonText}
              onClick={() => {
                scrollToCategory(index);
                handleDrawerClose();
              }}
            />
          </ListItem>
        ))}
      </List>
    </SwipeableDrawer>
  );
}
