import React from "react";

import { Typography } from "@mui/material";

export default function Gifts() {
  return (
    <Typography align="center">
      Vezměte toto oznámení jako malou radu,
      <br />
      že svatba není o trápení, co dát ke svatebnímu daru.
      <br />
      Pokud něčím přispějete, budeme za to rádi,
      <br />
      důležitější však pro nás bude, že strávíte tento den s námi.
    </Typography>
  );
}
