import React from "react";

import { Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  copyright: {
    paddingTop: "32px",
  },
}));

export default function Copyright() {
  const { classes } = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.copyright}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://znoj.cz/">
        znoj.cz
      </Link>{" "}
      2022
    </Typography>
  );
}
