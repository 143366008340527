import "./App.css";

import React from "react";

import { Box, Container, Divider, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import AppContent from "./AppContent";
import BackToTop from "./Components/BackToTop";
import Navigation from "./Components/Navigation";
import Copyright from "./Pages/Copyright";
import { CATEGORIES } from "./constants";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "scroll-behavior": "smooth",
  },
  categoryZeroContainer: {
    margin: 0,
    padding: 0,
  },
  lastDivider: {
    paddingBottom: "64px",
  },
}));

export default function App() {
  const { classes } = useStyles();

  return (
    <React.Fragment>
      <div id="category" key={0} className={classes.categoryZeroContainer} />
      {/* <TopCarousel /> */}
      <div id={`category-0`}></div>
      <Navigation categories={Object.keys(CATEGORIES)} />
      <Container maxWidth="lg">
        <Box my={4} style={{ marginTop: 0 }}>
          <AppContent categories={Object.entries(CATEGORIES)} />
          <Divider variant="middle" light className={classes.lastDivider} />
          <Copyright />
        </Box>
        <BackToTop />
      </Container>
    </React.Fragment>
  );
}
