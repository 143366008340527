import React from "react";

import { Box, Divider, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ICategoriesProps } from "./types";
import CarouselItem from "./Components/TopCarousel/CarouselItem";

const useStyles = makeStyles()((theme: Theme) => ({
  categoryContainer: { paddingTop: 64 },
  title: { paddingTop: 16 },
  categoryTitle: {
    paddingBottom: "8px",
  },
}));

export default function AppContent(props: ICategoriesProps) {
  const { categories } = props;
  const { classes } = useStyles();

  return (
    <React.Fragment>
      {
        <Typography
          variant="h6"
          color="primary"
          align="center"
          className={classes.title}
        ></Typography>
      }
      {
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={classes.title}
        >
          <b>svatba J & J</b>
        </Typography>
      }
      <Typography
        variant="h4"
        color="primary"
        align="center"
        className={classes.title}
      >
        <CarouselItem key={"carouselItem"} imagePath={"0"} />
      </Typography>
      {categories
        .slice(1)
        .map((category: [string, JSX.Element], index: number) => (
          <div
            id={`category-${index + 1}`}
            key={index + 1}
            className={classes.categoryContainer}
          >
            <Box mb={0}>
              <Divider variant="middle" light />
              <Typography variant="h5" className={classes.categoryTitle}>
                {category[0]}
              </Typography>
              {category[1]}
            </Box>
          </div>
        ))}
    </React.Fragment>
  );
}
