import React from "react";

import { Grid } from "@mui/material";

import PersonCard from "../Components/PersonCard";

export default function Driver() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PersonCard
          imagePath={"/people/ridic1"}
          title={"Štěpán Valenta"}
          description={<a href="#">Řidič Štěpán</a>}
        />
        <PersonCard
          imagePath={"/people/ridic2"}
          title={"František Rusnák"}
          description={<a href="#">Řidič František</a>}
        />
        <PersonCard
          imagePath={"/people/ridic3"}
          title={"Josef Macoun"}
          description={<a href="#">Řidič Jožka</a>}
        />
      </Grid>
    </React.Fragment>
  );
}
