import React from "react";

import { Grid } from "@mui/material";

import PersonCard from "../Components/PersonCard";

export default function We() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <PersonCard
        imagePath={"/people/jana"}
        title={"Jana"}
        description={"Nevěsta"}
      />
      <PersonCard
        imagePath={"/people/jachym"}
        title={"Jáchym"}
        description={"Ženich"}
      />
    </Grid>
  );
}
