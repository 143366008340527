import React from "react";

import { Grid } from "@mui/material";

import PersonCard from "../Components/PersonCard";

export default function Contacts() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PersonCard
          imagePath={"/people/d"}
          title={"Daniela Znojová"}
          description={<a href="#">Svědkyně</a>}
        />
        <PersonCard
          imagePath={"/people/vaclav"}
          title={"Václav Martinec"}
          description={<a href="#">Svědek</a>}
        />
      </Grid>
    </React.Fragment>
  );
}
