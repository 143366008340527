import React from "react";

import { Button, Divider, List, ListItem, ListItemText } from "@mui/material";

import { scrollToCategory } from "../utils";
import { CATEGORIES } from "../constants";

export default function Info() {
  return (
    <React.Fragment>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <>
                Na večerní hostinu jsou zváni všichni ti, kdo k svatebnímu
                oznámení obdrželi i kartičku s
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() => window.open("/invite/hostina.jpg", "_blank")}
                >
                  pozvánkou na svatební hostinu
                </Button>
                či jim to bylo přímo řečeno, podobně tak s
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() => window.open("/invite/obed.jpg", "_blank")}
                >
                  pozváním na rodinný oběd
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Prosíme, aby hosté zvaní na oběd, přicházeli do kulturního domu zadním vchodem - od paneláků."
            secondary="Hosty přicházející na večerní párty rádi přivítáme u hlavního vchodu - od kostela."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Prosíme, nedělejte nepořádek u nevěsty na zahradě v předvečer svatby."
            secondary="Nikomu by to radost neudělalo a bez této tradice se rádi obejdeme."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Prosíme, neparkujte před vchodem do kostela v Rapotíně. Využijte
                místa uvedená v sekci
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(
                      Object.keys(CATEGORIES).indexOf("Parkování")
                    )
                  }
                >
                  Parkování
                </Button>
                .
              </>
            }
            secondary="Místo je rezervováno pro nevěstu."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Prosíme, neparkujte před vchodem do kulturního domu v Bludově.
                Využijte místa uvedená v sekci
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(
                      Object.keys(CATEGORIES).indexOf("Parkování")
                    )
                  }
                >
                  Parkování
                </Button>
                .
              </>
            }
            secondary="Místo je rezervováno pro novomanžele a svatebního řidiče."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Prosíme, nepřipravujte únos nevěsty."
            secondary="Rádi bychom prožili svatební den spolu a se svatebními hosty."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Ubytování pro svatební hosty"
            secondary={
              <>
                Ubytování bude možné buď na pokojích{""}
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    window.open("https://mapy.cz/s/kurerolohu", "_blank")
                  }
                >
                  internátu
                </Button>
                {""}v Šumperku (280 Kč/os/noc) nebo zdarma na{""}
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    window.open("https://mapy.cz/s/lolunuzeho", "_blank")
                  }
                >
                  faře
                </Button>
                {""}v Bludově na zemi s využitím vlastní karimatky a spacáku. V
                případě zájmu o některou z těchto forem ubytování kontaktujte
                prosím co nejdříve
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(
                      Object.keys(CATEGORIES).indexOf("Snoubenci")
                    )
                  }
                >
                  nevěstu
                </Button>
                či
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(
                      Object.keys(CATEGORIES).indexOf("Snoubenci")
                    )
                  }
                >
                  ženicha
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Na svatební hostinu se prosím dopravte sami, odvoz z hostiny na
                ubytování/domů zajišťují naši
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(Object.keys(CATEGORIES).indexOf("Řidiči"))
                  }
                >
                  svatební řidiči
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Na svatbě bude přítomen fotograf, který dokáže zachytit důležité
                okamžiky mnohem lépe než Vaše mobilní telefony. Proto Vás
                prosíme nedělejte během obřadu vlastní fotografie, ať není
                narušena slavnostní atmosféra. Svatební fotografie od
                profesionála budete mít následně k dispozici i vy.
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Svatba se ponese v duchu barev lesa, ale není zapotřebí žádný
                speciální dress code.
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Do dne svatby se prosím obracejte s dotazy na{" "}
                <Button
                  color="primary"
                  key={"snoubenci"}
                  onClick={() => scrollToCategory(2)}
                >
                  snoubence
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                V den svatby se prosím obracejte na
                {
                  <Button
                    color="primary"
                    key={`kontaktyButton`}
                    onClick={() =>
                      scrollToCategory(
                        Object.keys(CATEGORIES).indexOf("Kontakty")
                      )
                    }
                  >
                    Svědka a svědkyni
                  </Button>
                }
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                Při příjezdu na svatební obřad se budou zdobit auta stužkami u
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    window.open("https://mapy.cz/s/fubugugezo", "_blank")
                  }
                >
                  CBA obchodu
                </Button>
                {""}
                poblíž kostela.
              </>
            }
          />
        </ListItem>
        <Divider />
      </List>
    </React.Fragment>
  );
}
