import React from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface IPersonCard {
  imagePath: string;
  title: string;
  description: string | JSX.Element;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    width: "100%",
    display: "block",
    position: "relative",
    maxWidth: 345,
    margin: "0 auto",
    textAlign: "center",
    "&:hover": {
      "& $cardContent": {
        backgroundColor: "rgba(255, 255, 255, 1)",
      },
    },
  },
  cardContent: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    left: "0px",
    alignItems: "center",
    textAlign: "center",
    backgroundImage:
      "linear-gradient( to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1))",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  media: {
    height: 340,
  },
}));

export default function PersonCard(props: IPersonCard) {
  const { imagePath, title, description } = props;
  const { classes } = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.card} raised>
        <CardMedia className={classes.media} image={`${imagePath}.jpg`} />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
