import React from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { IPlaceCard } from "../types";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    width: "100%",
    display: "block",
    position: "relative",
    maxWidth: 345,
    margin: "0 auto",
    textAlign: "center",
    "&:hover": {
      "& $cardContent": {
        backgroundColor: "rgba(255, 255, 255, 1)",
      },
    },
  },
  cardContent: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    left: "0px",
    alignItems: "center",
    textAlign: "center",
    backgroundImage:
      "linear-gradient( to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1))",
  },
  media: {
    height: 340,
  },
}));

export default function PlaceCard(props: IPlaceCard) {
  const { imagePaths, title, description, gMapLink, mapLink, onClick, style } =
    props;
  const { classes } = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card className={classes.card} raised onClick={onClick} style={style}>
        {
          // animation disabled
          /* <Carousel
          animation={'fade'}
          autoPlay={true}
          stopAutoPlayOnHover={true}
          interval={8000}
          indicators={false}
        >
          {imagePaths.map((imagePath) => (
            <CardMedia
              key={imagePath}
              className={classes.media}
              image={`${imagePath}.jpg`}
            />
          ))}
        </Carousel> */
        }
        <CardMedia
          key={imagePaths[0]}
          className={classes.media}
          image={`${imagePaths[0]}.jpg`}
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5">
            {title}
          </Typography>
          <Typography gutterBottom variant="body1">
            {description}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {mapLink ? (
              <Link
                href={mapLink}
                key={"mapy"}
                target="_blank"
                rel="noopener"
                variant="body2"
                onClick={(e: any) => e.stopPropagation()}
              >
                Mapy.cz
              </Link>
            ) : null}
            {gMapLink ? (
              <Link
                href={gMapLink}
                key={"gmaps"}
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Google mapy
              </Link>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
