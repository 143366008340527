import { Grid } from "@mui/material";
import React from "react";

export default function Video() {
  const width = (+window.innerWidth > 1200? 1200: +window.innerWidth) - 32; // 32 is 2x16 padding
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
      >
        <iframe width={width} height={width * 0.5625} src="https://www.youtube.com/embed/ILDqNm9-bd8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Grid>
    </React.Fragment>
  );
}
