import React from "react";

import { makeStyles } from "tss-react/mui";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { tableDataType } from "../types";

const useStyles = makeStyles()({
  table: {
    margin: "0 auto",
    width: "300px",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
});

function createData(time: string, activity: string) {
  return { time, activity };
}

const commonRows = [createData("11:00", "Obřad")];
const commonParty = [createData("18:30", "Volná zábava doplněná o program")];
const rows = [
  ...commonRows,
  createData("13:00", "Rodinný oběd"),
  createData("14:30", "Rodinné focení, odvoz aut, podávání kávy"),
  createData("16:00", "Krájení svatebního dortu"),
  createData("17:00", "Svatební hostina, gratulace"),
  createData("17:30", "První novomanželský tanec"),
  createData("18:00", "Focení skupinek"),
  ...commonParty,
];

function ProgramTabPanel(props: { customRows?: tableDataType }) {
  const { classes } = useStyles();

  return (
    <div
      role="tabpanel"
      id={`full-width-tabpanel`}
      aria-labelledby={`full-width-tab`}
    >
      <Table className={classes.table} aria-label="program table">
        <TableBody>
          {(props.customRows || rows).map((row) => (
            <TableRow key={row.time}>
              <TableCell
                component="th"
                scope="row"
                align="center"
                className={classes.tableCell}
              >
                {row.time}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {row.activity}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default ProgramTabPanel;
