import React from "react";

import { makeStyles } from "tss-react/mui";
import { IconButton, Link } from "@mui/material";
import { scrollToCategory } from "../../utils";

import MenuIcon from "@mui/icons-material/Menu";

interface IMobileMenu {
  setOpen: (param: boolean) => void;
}

const useStyles = makeStyles()((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  link: {
    width: "100%",
    paddingRight: "48px",
    textAlign: "center",
  },
}));

export default function MobileMenu(props: IMobileMenu) {
  const { classes } = useStyles();
  const { setOpen } = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Link
        href="#"
        color="secondary"
        key={"top"}
        underline={"none"}
        className={classes.link}
        onClick={() => scrollToCategory(0)}
      >
        {"J&J Svatba"}
      </Link>
    </div>
  );
}
