import React, { useEffect } from "react";

import { makeStyles } from "tss-react/mui";
import { Box, Paper, TableContainer } from "@mui/material";

import ProgramTabPanel from "../Components/ProgramTabPanel";

const useStyles = makeStyles()({
  tableContainer: {
    maxWidth: 600,
    minWidth: 300,
  },
  box: {
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});

export default function Program() {
  const { classes } = useStyles();

  return (
    <>
      <Box my={4} className={classes.box}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <ProgramTabPanel />
        </TableContainer>
      </Box>
    </>
  );
}
