import { Grid } from "@mui/material";
import React from "react";

import PhotoCard from "../Components/PhotoCard";

export default function Photos() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PhotoCard
          key={"ochutnavka"}
          imagePaths={["/svatebniFotky"]}
          zoneramaLink={
            "https://eu.zonerama.com/dominiknovak/Album/8715070?secret=SCGW89E14JU25BOiOBd4Ta0Hr"
          }
          title={"Svatební fotky"}
          description={""}
        />
        <PhotoCard
          key={"fotkyOndra"}
          imagePaths={["/fotkyOndra"]}
          zoneramaLink={
            "https://eu.zonerama.com/jedlickovi-svatba/Album/9367183"
          }
          title={"Fotky od Ondry"}
          description={""}
        />
      </Grid>
    </React.Fragment>
  );
}
