import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const primaryColor = "#294e04";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ["Nunito", "Tangerine"].join(","),
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: "#f0f0f0",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
